export var _op_row = `
<tr id="{{id}}" class="operation_row cursor-pointer {{#if has_password}}protected{{/if}} {{#if ended_at}}finished_operation_row{{^}}not_finished_operation_row{{/if}}">
  <td onclick="redirectToOperation('{{id}}')" class="" style="text-align:center">
    {{#if hidden}}
      <i class="far fa-eye-slash" data-toggle="tooltip" title="{{i18n 'operation.index.operation_hidden'}}"></i>
    {{else}}
      {{#unless visible}}
        <i class="far fa-user-secret hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.index.operation_secret'}}"></i>
      {{/unless}}
    {{/if}}
    {{#if featured}}
        <i class="far fa-map-pin hover-show-explanation" style="color: {{current_tenant.theme_color}}" data-toggle="tooltip" title="{{i18n 'operation.index.pinned_op'}}"></i>
    {{/if}}
    {{#if has_password}}
        <i class="far fa-times-circle hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.index.operation_locked'}}"></i>
    {{/if}}
    {{#if current_tenant.open_operations}}
      {{#unless open}}
          <i class="far fa-shield hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.index.operation_closed'}}"></i>
      {{/unless}}
    {{^}}
      {{#if open}}
          <i class="far fa-unlock hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.index.operation_open'}}"></i>
      {{/if}}
    {{/if}}
    {{#if disruption_report_nr}}
        <i class="far fa-flag hover-show-explanation" style="color: {{current_tenant.theme_color}}" data-toggle="tooltip" title="{{disruption_report_nr}}"></i>
    {{/if}}
    {{#LastTimeViewedHelper}}
      <i class="far fa-exclamation-circle hover-show-explanation" style="color: {{current_tenant.theme_color}}" data-toggle="tooltip" title="{{i18n 'operation.index.operation_has_updates'}}"/>
    {{/LastTimeViewedHelper}}
    {{#with shared_operation_tenants}}
        <i class="far fa-handshake hover-show-explanation" data-toggle="tooltip" data-html="true" title="{{i18n 'operation.index.op_owner'}}: {{../tenant.name}} <br> {{i18n 'operation.index.shared_with'}}: {{#each ../shared_operation_tenants}}<br/>{{tenant.name}}{{/each}}"/>
    {{/with}}
  </td>
  <td onclick="redirectToOperation('{{id}}')" class="" style="width:auto;">
     {{#if governance_levels}}
        <small>
        {{#each governance_levels}}
          <span class="badge badge-primary">&lt;{{name}}&gt;</span>
        {{/each}}
        </small>
        <br>
      {{/if}}
      {{#if allowed_units}} 
        <small>
        {{#each allowed_units}}
          <span class="text-muted" title="{{name}}">[{{call_sign}}] </span>
        {{/each}}
        </small>
        <br>
      {{/if}}
      <span>{{#if shared_operation_tenants}}<span class="badge" title="{{tenant.name}}" style="background-color: {{tenant.theme_color}}; color: {{getTextColor tenant.theme_color}};" >{{tenant.abbreviation}}</span>&nbsp;{{/if}}{{name}}</span>

      {{#with custom_values}}
        {{#ifContains 1ad76c68-b2f3-4a4b-9435-9791e51282ae.value "k3EGsKRvuq06R2bEkL6pog"}}
          <i class="hover-show-explanation far fa-shield" data-toggle="tooltip" title="Gæsla"></i>
        {{/ifContains}}
        {{#ifContains 1ad76c68-b2f3-4a4b-9435-9791e51282ae.value "4xsk4HJ8j6iFazinw2XoXg"}}
          <i class="hover-show-explanation far fa-ambulance" data-toggle="tooltip" title="Björgun á fólki"></i>
        {{/ifContains}}
        {{#ifContains 1ad76c68-b2f3-4a4b-9435-9791e51282ae.value "WBMxwMWB2-FK50GlOoZaWQ"}}
          <i class="hover-show-explanation far fa-home" data-toggle="tooltip" title="Verðmætabjörgun"></i>
        {{/ifContains}}
        {{#ifContains 1ad76c68-b2f3-4a4b-9435-9791e51282ae.value "sngs5t-AE3A-nNzyYbQoMA"}}
          <i class="hover-show-explanation far fa-search" data-toggle="tooltip" title="Leit"></i>
        {{/ifContains}}
      {{/with}}
      <br>
      {{#displayLengthAndPersonnel current_tenant.tenant_type.short_name}}
      <small>
        {{#if ended_at}}
          <i class="far fa-clock hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.index.duration'}}"></i> {{hours_between started_at ended_at}} {{i18n 'operation.index.hrs'}}
        {{^}}
          <i class="far fa-play-circle hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.index.duration'}} - {{i18n 'operation.index.in_progress'}}"></i> {{hours_between started_at ended_at}} {{i18n 'operation.index.hrs'}}
        {{/if}}
         <i class="far fa-user leftmargin-10"/> {{max_members}}
       </small>
      {{/displayLengthAndPersonnel}}
  </td>
  {{#if energy_tenant}}
    {{#ifEquals priority.name "F1: Útleysing/bilun"}}
      <td onclick="redirectToOperation('{{id}}')" class="text-center" style="color: {{operation_scale.text_color}}; background-color:{{operation_scale.background_color}}">{{priority.name}}<br>{{device_identification_code.code}}</td>
    {{else}}
      <td onclick="redirectToOperation('{{id}}')" class="text-center" style="background-color: whitesmoke;">{{priority.name}}<br>{{device_identification_code.code}}</td>
    {{/ifEquals}}
  {{else}}
    <td onclick="redirectToOperation('{{id}}')" class="text-center"
      style="color: {{operation_scale.text_color}} !important; background-color:{{operation_scale.background_color}} !important;"
    >
      {{operation_type.name}} {{#ifTenantHasAttribute current_tenant "priority_id"}} - {{priority.name}} {{/ifTenantHasAttribute}} {{#ifTenantHasAttribute current_tenant "operation_scale_id"}} - {{operation_scale.name}} {{/ifTenantHasAttribute}}<br>{{#ifTenantHasAttribute current_tenant "kind_id"}} {{kind.name}} {{/ifTenantHasAttribute}}
    </td>
  {{/if}}

  <td onclick="redirectToOperation('{{id}}')" class="text-center">
    {{#if started_at}}
        <p style="display:none">{{date started_at}}</p>
        {{datetime started_at}}
        {{#if ended_at}}
            <br>
            {{datetime ended_at}}
        {{/if}}
    {{/if}}
  </td>

  {{#ifTenantHasAttribute current_tenant "area_id"}}
    <td onclick="redirectToOperation('{{id}}')">
    {{#if area.name}}
      <i class="far fa-location-arrow"></i> {{area.name}}
    {{/if}}
    </td>
  {{/ifTenantHasAttribute}}
  {{#if energy_tenant}}
      <td onclick="redirectToOperation('{{id}}')" class="">{{regarding}}</td>
      <td onclick="redirectToOperation('{{id}}')" class="">{{category.name}}</td>
      <td onclick="redirectToOperation('{{id}}')" class="">{{datetime updated_at}}</td>
  {{/if}}

  {{#if redcross_tenant}}
    <td onclick="redirectToOperation('{{id}}')">
      {{#if data.risks_j}}
        <span class="badge  risk-label risk-{{max_value data.risks_j 'risk_factor'}}">
          {{max_value data.risks_j "risk_factor"}}
        </span>
          &nbsp;/
        <span class="badge  risk-label risk-{{max_value data.risks_j 'new_risk_assessment'}}">
          {{max_value data.risks_j "new_risk_assessment"}}
        </span>
      {{/if}}
    </td>
    <td class="text-center">{{#if number_of_coordinates}}<i class="far fa-check"></i>{{/if}}</td>
  {{/if}}

  {{#if sar_tenant}}
    <td onclick="redirectToOperation('{{id}}')">
      {{#if data.risks_j}}
        <span class="badge risk-label risk-{{max_value data.risks_j 'risk_factor'}}">
          {{max_value data.risks_j "risk_factor"}}
        </span>
          &nbsp;/
        <span class="badge risk-label risk-{{max_value data.risks_j 'new_risk_assessment'}}">
          {{max_value data.risks_j "new_risk_assessment"}}
        </span>
      {{/if}}
    </td>
    <td class="text-center">{{#if number_of_coordinates}}<i class="far fa-check"></i>{{/if}}</td>
  {{/if}}

  {{#each current_tenant.custom_fields}}
    {{#if on_frontpage}}
    <td onclick="redirectToOperation('{{id}}')" class="text-center">{{get_custom_value ../custom_values this}}</td>
    {{/if}}
  {{/each}}
  <td>{{operation_logs_count}}</td>
  <td>{{groups_count}}</td>
  <td>{{tasks_count}}</td>
  <td style="text-align:center">
      <div class="dropdown" id="op_info_dropdown_container">
        <button class="btn btn-outline-secondary dropdown-toggle float-right" type="button" id="op_info_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <i class="far fa-ellipsis-h"></i>
          <span class="caret" id="op_info_dropdown_caret"></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="op_info_dropdown">
          <a class="dropdown-item" href="/operation/{{id}}"><i class="far fa-info-circle fa-fw"></i>{{i18n 'operation.index.progress'}}</a>
          <a class="dropdown-item" href="/operation/{{id}}/search_areas"><i class="far fa-map fa-fw"></i>{{i18n 'operation.index.area'}}</a>
          <a class="dropdown-item" href="/operation/{{id}}/setup"><i class="far fa-cog fa-fw"></i>{{i18n 'operation.index.settings'}}</a>
          {{#if client_isadmin}}
            <div class="dropdown-divider"></div>

            <a href="/operation/{{id}}/send_survey" onclick="return confirm('{{i18n 'helpers.are_you_sure'}})'" class="dropdown-item" data-opid="{{id}}"><i class="far fa-poll fa-fw"></i>{{i18n 'operation.setup.send_survey'}}</a>
            {{#if hidden}}
              <a href="/operation/{{id}}/unhide" onclick="return confirm('{{i18n 'helpers.are_you_sure'}})'" class="dropdown-item" data-opid="{{id}}"><i class="far fa-eye fa-fw"></i>{{i18n 'operation.index.show_op'}}</a>
            {{^}}
              <a href="/operation/{{id}}/hide" onclick="return confirm('{{i18n 'helpers.are_you_sure'}}')" class="dropdown-item" data-opid="{{id}}"><i class="far fa-eye-slash fa-fw"></i>{{i18n 'operation.index.hide_op'}}</a>
            {{/if}}
            {{#if featured}}
              <a class="dropdown-item" href="/operation/{{id}}/toggle_featured"><i class="far fa-times fa-fw"></i>{{i18n 'operation.index.unfeature_op'}}</a>
            {{^}}
              <a class="dropdown-item" href="/operation/{{id}}/toggle_featured"><i class="far fa-map-pin fa-fw"></i>{{i18n 'operation.index.feature_op'}}</a>
            {{/if}}
          {{/if}}
          {{#if client_ismanager}}
            <div class="dropdown-divider"></div>
            <a href="/operation/{{id}}/deregister" onclick="return confirm('{{i18n 'helpers.are_you_sure'}}')" class="dropdown-item"><i class="far fa-trash fa-fw"></i>{{i18n 'operation.index.unregister'}}</a>
          {{/if}}

        </ul>
      </div>
    </td>
</tr>
`;
